@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $white;

	padding: 6rem 6rem 0;

	@include sm-down {
		padding: 6rem 1.5rem 0;
	}

	isolation: isolate;
}

.content {
	max-width: $max-width;
	margin-inline: auto;
	position: relative;
	width: 100%;
}

.shade {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 75%);
	z-index: 1;

	border-radius: 1rem;

	@include sm-down {
		display: none;
	}
}

.media {
	object-fit: cover;
	position: relative;

	width: 100%;
	display: block;
	line-height: 0;

	border-radius: 1rem;

	min-height: 60rem;
	max-height: 100vh;

	z-index: -2;

	@include sm-down {
		height: auto;
		min-height: auto;
		max-height: none;
		width: 100%;
		aspect-ratio: 16 / 9;
	}
}

.content header {
	position: absolute;
	z-index: 2;

	bottom: 6rem;
	width: 100%;
	padding: 6rem 2rem;

	text-align: center;

	@include sm-down {
		position: relative;
		color: $black;
		bottom: auto;

		padding: 0;
		margin-bottom: 3rem;
	}
}

.content h2 {
	max-width: 20ch;

	margin-inline: auto;
}

.content header > p {
	margin-bottom: 1.5rem;

	max-width: 42ch;

	margin-inline: auto;
}
