@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;
	overflow: hidden;
	isolation: isolate;

	@include section-padding;
}

.container > article {
	overflow: hidden;
	margin-inline: auto;

	position: relative;

	&:not(:last-of-type) {
		margin-bottom: 7rem;
	}
}

.container article > picture {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	user-select: none;
	pointer-events: none;
	z-index: -1;
}

.graph_content {
	max-width: 72rem;
	margin-inline: auto;
	@include sm-down {
		text-align: center;
	}
}

.graph_content h3 {
	max-width: 16.6ch;
	margin-bottom: 2.5rem;
	margin-inline: auto;
	text-align: center;
}

.graph_content h4 {
	margin-bottom: 1.5rem;

	@include sm-down {
		text-align: center;
		margin-inline: auto;
	}
}

.graph_content_inner {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: auto 1fr;
	gap: 1.5rem;

	column-gap: 3rem;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 3rem;
	}
}

.graph_content_header {
	grid-column: 1;
	grid-row: 1;

	@include sm-down {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.center_content {
	grid-column: 1;
	grid-row: 2;

	position: relative;

	* {
		border-radius: 1rem;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@include sm-down {
		grid-column: 1;
		grid-row: 3;
		height: 30rem;
	}

	img {
		border-radius: 1rem;
	}
}

.bars {
	grid-column: 2;
	grid-row: 1/3;

	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;

	@include sm-down {
		margin-inline: auto;
		grid-column: 1;
		grid-row: 2;

		width: 100%;

		li {
			width: 100%;
		}
	}

	margin: 0;
}

.bar_container {
	padding: 2rem 2.5rem;

	border-radius: 1.5rem;
	border: 1px solid rgba(183, 183, 183, 0.5);

	background: rgba(204, 204, 204, 0.4);

	backdrop-filter: blur(20px);
	text-align: left;

	max-width: 22rem;

	@include sm-down {
		max-width: unset;
		width: 100%;
	}
}

.number {
	margin-bottom: 0.5rem;
}

.bar_title {
}

.runner_image img {
	object-position: 50% 60%;
}

.bike_image img {
	object-position: 50% top;
}

.yoga_image img {
	object-position: 50% 10%;

	@include sm-down {
		object-position: 90% 20%;
	}
}

.modal_image_container {
	margin-top: 6rem;
	@include sm-down {
		margin-top: 3rem;
	}
}

/**
	Modal content
 */

.modal_image_container img {
	max-width: clamp(20rem, 75vw, 80rem);
	max-height: clamp(15rem, 75vh, 50rem);
	margin-inline: auto;
	border-radius: 1rem 1rem 0 0;
	object-position: center 23%;
}

.modal_content {
	padding: 10rem clamp(4rem, 10vw, 16rem) 0;
	box-sizing: content-box;

	@include sm-down {
		padding: 3rem 1.5rem 0;
	}
}

.modal_content b {
	font-weight: 500;
}

.modal_content h2 {
	margin-bottom: 4rem;
	max-width: 25ch;
	@include sm-down {
		margin-bottom: 1.5rem;
	}
}

.modal_content h3 {
	margin-top: 4rem;
	margin-bottom: 1.5rem;

	@include sm-down {
		margin-top: 2.5rem;
		margin-bottom: 1.5rem;
	}
}

.modal_content ul {
	margin-left: 2rem;
}

.modal_content p,
.modal_content li {
	color: #717171;
	margin-bottom: 1em;

	@include sm-down {
		margin-bottom: 1em;
	}
}

.modal_content table {
	border-spacing: 0;
	margin: 5rem 0;

	@include sm-down {
		margin: 2rem 0;
	}
}

.modal_content th {
	padding: 1rem 1.5rem;

	text-align: left;

	border-bottom: 1px solid #dbdbdb;
	background: #f2f2f2;
	&:first-child {
		border-radius: 1rem 0 0 0;
	}

	&:last-child {
		border-radius: 0 1rem 0 0;
		border-left: 1px solid #dbdbdb;
	}

	@include sm-down {
		padding: 0.5rem 1rem;

		&:first-child {
			border-radius: 0.5rem 0 0 0;
		}

		&:last-child {
			border-radius: 0 0.5rem 0 0;
		}
	}
}

.modal_content td {
	border-bottom: 1px solid #dbdbdb;
	border-left: 1px solid #dbdbdb;

	&:last-child {
		border-right: 1px solid #dbdbdb;
	}
}

.modal_content td {
	padding: 1rem 1.5rem;
	@include sm-down {
		padding: 0.5rem 1rem;
	}
}

.modal_content tr:last-child td {
	&:first-child {
		border-radius: 0 0 0 1rem;
	}
	&:last-child {
		border-radius: 0 0 1rem 0;
	}

	@include sm-down {
		&:first-child {
			border-radius: 0 0 0 0.5rem;
		}
		&:last-child {
			border-radius: 0 0 0.5rem 0;
		}
	}
}

.cta {
	margin-top: 1.5rem;
}
