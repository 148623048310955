@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	&[data-theme='dark'] {
		background: linear-gradient(180deg, #0e0e0e 0%, #1b1b1b 100%);
		color: $text-white;
	}

	&[data-theme='light'] {
		background: $background-white;
		color: $text-black;

		.eyebrow {
			color: $text-grey-dark;
		}
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	padding: 7rem 1.75rem;

	position: relative;

	isolation: isolate;

	@include sm-down {
		padding: 4rem 1.25rem;
	}
}

.container header {
	margin-bottom: 2.5rem;

	@include sm-down {
		h2 {
			margin-inline: auto;
		}

		margin-bottom: 2rem;
	}
}

.moire_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	overflow: hidden;
}

.moire_container img {
	opacity: 0.2;

	&:first-child {
		position: absolute;
		top: 20%;
		right: -5rem;

		height: 14rem;
	}

	&:last-child {
		position: absolute;
		bottom: -5rem;
		left: 0;

		height: 14rem;
	}

	@include sm-down {
		&:first-child {
			top: 10%;
			right: -15rem;
		}

		&:last-child {
			bottom: 10rem;
			left: -5rem;
		}
	}
}

.leader_list {
	list-style: none;

	display: grid;

	grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
	max-width: 90rem;

	gap: 2.5rem;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.leader {
	display: flex;

	flex-direction: column;

	text-align: left;
}

.leader img {
	max-width: 30rem;
	border-radius: 0.5rem;

	margin-bottom: 2rem;

	aspect-ratio: 375 / 300;
}

.leader p {
	opacity: 0.65;
}

.cta {
	margin-top: 2.5rem;
}
