@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;

	width: 100%;

	padding: 12rem 4rem;

	@include sm-down {
		padding: 3.75rem 1.5rem 6rem;
	}
}

.content {
	max-width: 80rem;
	margin-inline: auto;

	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 6rem;

	justify-content: space-between;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 3rem;
	}
}

.content header {
	@include sm-down {
		text-align: center;

		border-radius: 0.5rem;
		background-color: #f1f2f4;

		padding: 3rem 1.5rem;
	}
}

.content header > p {
	margin-bottom: 1.5rem;
}

.benefits {
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin: 1rem auto 0;

	@include sm-down {
		margin: 0 auto;
	}
}

.benefits li {
	display: grid;
	align-items: center;
	grid-template-columns: 2rem auto;
	gap: 1rem;
}

.benefits svg {
	width: 2rem;
	height: 2rem;
}
